<template>

  <div class='view'>
    <top-nav active="99" :istop="true"></top-nav>
    <div class="topnav_bg_90"></div>
    <div class="about_us">
      <div class="about_us_mission">
        <p class="about_us_mission_title">Mission Statement</p>
        <p class="about_us_mission_content">To connect all professionals involved in the project marketing field within the real estate industry, through a simplified, multi-faceted platform, creating seamless integrated workflows.
        </p>
        <div class="quote_img">
          <img src="@/assets/imgs/pc/aboutus/quote_img.png" alt="">
        </div>
      </div>
      <div class="about_us_content">
        <p class="about_us_content_title">About Us</p>
        <div class="about_us_content_message">
          <div class="about_us_content_message_inner about_us_content_message_left">
            <p><span style="font-family: Poppins-SemiBold;font-size: 20px;line-height: 1.5; color: #314455;">Arcanite </span>is a comprehensive project marketing software solution for the real estate sector. Designed to aggregate all aspects of the project marketing process from a single centralised platform, Arcanite’s unique interface provides a unified space for all property professionals.
            </p>
            <p>
              New property development sales are typically distributed through multiple agencies, channels, and sales platforms, with each group utilising varying software for tracking sales, reporting, and managing teams.
            </p>
            <p>
              Arcanite's automated system provides seamless connectivity between a multitude of users, allowing developers, agents and their professional associates to view all information for multiple developments and their project marketing initiatives, in real time.
            </p>
          </div>
          <div class="about_us_content_message_inner">
            <p>The multi-faceted platform streamlines stock distribution, sales management, administrative tasks, live updates, customer profiles, lead generation, file sharing, and much more, automating all workflows from the convenience of a mobile device.</p>
            <p>Additionally, Arcanite facilitates the aggregation of external systems, with the capability to sync with all major CRM programs such as Salesforce, Hubspot and Zapier, and integrate industry relevant systems such as REA, Domain and Mailchimp.</p>
            <p>Arcanite users can manage all workflows from the one platform and enjoy substantial savings of time and money.</p>
            <p>For more information, or to book a demo, simply click the button below.</p>
          </div>
        </div>
        <div @click="showPopupWidget" class="about_us_book_a_demo">Book A Demo</div>
      </div>
      <div class="recognised_by">
        <div class="recognised_by_title">Recognised By</div>
        <div class="recognised_by_content">
          <!-- <div class="recognised_by_content_item">
            <img class="acs" src="@/assets/imgs/pc/badges/acs@3x.png" alt="">
            <p class="badge_title">Member of</p>
            <p class="badge_type">ACS</p>
          </div>
          <div class="recognised_by_content_item">
            <img class="sc" src="@/assets/imgs/pc/badges/sc@3x.png" alt="">
            <p class="badge_title">Member of</p>
            <p class="badge_type">Stone & Chalk</p>
          </div>
          <div class="recognised_by_content_item">
            <img class="dd" src="@/assets/imgs/pc/badges/dd@3x.png" alt="">
            <p class="badge_title">Proptech Awards Finalist</p>
            <p class="badge_type">Design & Development</p>
          </div>
          <div class="recognised_by_content_item">
            <img class="oa" src="@/assets/imgs/pc/badges/oa@3x.png" alt="">
            <p class="badge_title">Proptech Awards Finalist</p>
            <p class="badge_type">Operations & Administration</p>
          </div> -->
          <el-carousel height="320px" indicator-position="outside">
            <el-carousel-item v-for="(badge_collect,j) in badgelist" :key="j">
              <div class="carou_item_clients_badge">
                <span class="carou_item_badge_logo" v-for="(d,i) in badge_collect" :key="i">
                  <div class="recognised_by_content_item">
                    <img class="acs" :src="d.badge_logo" alt="">
                    <p class="badge_title">{{d.badge_title}}</p>
                    <p class="badge_type">{{d.badge_subtitle}}</p>
                  </div>
                  <!-- <img :src="d.badge_logo" :class="{'height_rely_img':d.badge_name=='Foye','width_rely_img':d.badge_name!='Foye'}" alt=""> -->
                </span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="trusted">
        <trusted class="title-size"></trusted>
      </div>
      <!-- <div class="trusted_by">
        <div class="trusted_by_title">Trusted By</div>
        <div class="trusted_by_icon_content">
          <el-carousel :interval="10000" height="226px" indicator-position="outside">
            <el-carousel-item v-for="(company_collect,j) in companylist" :key="j">
              <div class="carou_item_clients">
                <span class="carou_item_client_logo" v-for="(d,i) in company_collect" :key="i">
                  <img :src="d.company_logo" :class="{'height_rely_img':d.company_name=='Foye','width_rely_img':d.company_name!='Foye'}" alt="">
                </span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> -->
    </div>
    <foot-nav></foot-nav>
  </div>
</template>

<script>
export default {
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "foot-nav": resolve => require(["./FooterNav.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
  },
  data() {
    return {
      companylist:[
        [{
          company_name: "Mcgrath",
          company_logo: require('@/assets/imgs/pc/trustBy/Mcgrath.jpeg')
        }, {
          company_name: "Stone",
          company_logo: require('@/assets/imgs/pc/trustBy/stone.jpeg')
        }, {
          company_name: "Piety",
          company_logo: require('@/assets/imgs/pc/trustBy/Piety.jpeg')
        }, {
          company_name: "Coronation",
          company_logo: require('@/assets/imgs/pc/trustBy/cor.jpeg')
        }, {
          company_name: "SquareYards",
          company_logo: require('@/assets/imgs/pc/trustBy/squareyard.jpeg')
        },{
          company_name: "SH",
          company_logo: require('@/assets/imgs/pc/trustBy/SH.jpeg')
        }, {
          company_name: "QuarterAcre",
          company_logo: require('@/assets/imgs/pc/trustBy/QuarterAcre.jpeg')
        }, {
          company_name: "Edge",
          company_logo: require('@/assets/imgs/pc/trustBy/Edge.jpeg')
        }, {
          company_name: "LivePropertyMarketing",
          company_logo: require('@/assets/imgs/pc/trustBy/LivePropertyMarketing.jpeg')
        }, {
          company_name: "Aland",
          company_logo: require('@/assets/imgs/pc/trustBy/Aland.jpg')
        }],
        [
        {
          company_name: "Ecospective",
          company_logo: require('@/assets/imgs/pc/trustBy/Ecospective.jpeg')
        }, {
          company_name: "ERA",
          company_logo: require('@/assets/imgs/pc/trustBy/ERA.jpg')
          
        }, {
          company_name: "Be100",
          company_logo: require('@/assets/imgs/pc/trustBy/be100.jpeg')
          
        }, {
          company_name: "idream",
          company_logo: require('@/assets/imgs/pc/trustBy/idream.png')
        }, {
          company_name: "Oxbridge",
          company_logo: require('@/assets/imgs/pc/trustBy/oxb.jpeg')
        },{
          company_name: "FamilyFirst",
          company_logo: require('@/assets/imgs/pc/trustBy/FamilyFirst.jpg')
        }, {
          company_name: "Libra",
          company_logo: require('@/assets/imgs/pc/trustBy/Libra.jpg')
        }, {
          company_name: "Qfirst",
          company_logo: require('@/assets/imgs/pc/trustBy/Qfirst.jpeg')
        }, {
          company_name: "Surplus",
          company_logo: require('@/assets/imgs/pc/trustBy/Surplus.jpeg')
        }, {
          company_name: "Wealthbridge",
          company_logo: require('@/assets/imgs/pc/trustBy/Wealthbridge.jpeg')
        }
      ]],
      badgelist:[
        [{
          badge_title:'Member of',
          badge_subtitle:'ACS',
          badge_logo:require('@/assets/imgs/pc/badges/acs@3x.png'),
        },
        {
          badge_title:'Member of',
          badge_subtitle:'Stone & Chalk',
          badge_logo:require('@/assets/imgs/pc/badges/sc@3x.png'),
        },
        {
          badge_title:'The Urban Developer Awards Finalist',
          badge_subtitle:'Best New PropTech',
          badge_logo:require('@/assets/imgs/pc/badges/TUDA2022.png'),
        },
        {
          badge_title:'Proptech Awards Finalist',
          badge_subtitle:'Efficiency & Optimisation',
          badge_logo:require('@/assets/imgs/pc/badges/Efficiency2022.png'),
        }
        ],
        [
          {
          badge_title:'Proptech Awards Finalist',
          badge_subtitle:'Design, Build & Develop',
          badge_logo:require('@/assets/imgs/pc/badges/Design2022.png'),
          },
          {
          badge_title:'Proptech Awards Finalist',
          badge_subtitle:'Proptech for Good',
          badge_logo:require('@/assets/imgs/pc/badges/Proptech2022.png'),
        },
        {
          badge_title:'Proptech Awards Finalist',
          badge_subtitle:'Design & Development',
          badge_logo:require('@/assets/imgs/pc/badges/oa@3x.png'),
        },
        {
          badge_title:'Proptech Awards Finalist',
          badge_subtitle:'Operations & Administration',
          badge_logo:require('@/assets/imgs/pc/badges/dd@3x.png'),
        }
        ]
      ]
    };
  },
  computed: {},
  watch: {},
  methods: {
    showPopupWidget() {
      Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      return false;
    },
  },
}
</script>
<style lang='scss' scoped>
.view {
  background-color: #f8f8f8;
  .about_us {
    background: transparent url("../../assets/imgs/pc/aboutus/bg_img.png")
      no-repeat center 0;
    background-size: 2560px 704px;
    .about_us_mission {
      width: 724px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      padding-top: 128px;
      text-align: center;
      .about_us_mission_title {
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #fff;
      }
      .about_us_mission_content {
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: normal;
        margin-top: 20px;
        text-align: center;
        color: #fff;
      }
      .quote_img {
        width: 42px;
        height: 36px;
        position: absolute;
        right: -42px;
        top: 145px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .about_us_content {
      margin-top: 120px;
      width: 998px;
      // height: 794px;
      padding: 30px;
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      border-radius: 5px;
      padding-bottom: 50px;
      border-radius: 5px;
      border: solid 1px #e6ebf2;
      .about_us_content_title {
		  padding-left: 10px;
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
      }
      .about_us_content_message {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .about_us_content_message_inner {
        //   border: 1px solid red;
          padding: 10px;
          width: 474px;
          p {
            font-family: Poppins;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            margin-bottom: 20px;
            letter-spacing: normal;
            text-align: left;
            color: #314455;
          }
        }
        .about_us_content_message_left {
          width: 454px;
        }
      }
      .about_us_book_a_demo {
        width: 144px;
        height: 42px;
        margin-top: 70px;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        border-radius: 5px;
        color: #fff;
        background-color: #1890ff;
        line-height: 42px;
        &:hover {
          cursor: pointer;
          background-color: #096dd9;
        }
      }
      .about_us_book_a_demo:active{
        background-color: #0757ae;
      }
    }
    .recognised_by {
      margin-top: 53px;
      padding-bottom:67px;
	    width:100%;
      .recognised_by_title {
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #062440;
      }
      .recognised_by_content {
        margin: 0px auto 0 auto;
        width: 1100px;
        .carou_item_clients_badge{
          height: 265px;
          display: flex;
          flex-wrap: wrap;
        }
        .carou_item_badge_logo {
            width: 250px;
            height: 105px;
            // margin-right: 45px;
            margin-left: 25px;
            margin-bottom: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            img.width_rely_img {
              width: 100%;
            }
            .height_rely_img {
              height: 100%;
            }
          }

        .recognised_by_content_item {
          width: 241px;
          height: 0px;
          // display: flex;
          // flex-direction: column;
        //   border: 1px solid blue;
          img {
            height: 105px;
          }
		  .sc{
			  height:92px;
			  margin-bottom: 13px;
		  }
          .badge_title {
            margin-top: 40px;
            font-family: Poppins-Bold;
            font-size: 20px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: center;
            color: #062440;
          }
          .badge_type {
            margin-top: 10px;
            font-family: Poppins;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #062440;
          }
        }
      }
    }

    .trusted{
      height: 300px;
      margin: 80px auto;
    }
    .trusted_by {
      padding-top: 136px;
      padding-bottom: 126px;
      background: #fff;
      .trusted_by_title {
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #062440;
      }
      .trusted_by_icon_content {
        margin: 90px auto 0 auto;
        width: 1115px;
        .carou_item_clients {
          height: 225px;
          display: flex;
          flex-wrap: wrap;
          .carou_item_client_logo {
            width: 175px;
            height: 70px;
            margin-right: 45px;
            margin-bottom: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            img.width_rely_img {
              width: 100%;
            }
            .height_rely_img {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

/deep/.title-size{
  // height: 400px !important;
  .title{
    width: 197px !important;
    height: 42px !important;
    font-family: 'Poppins-Bold'!important;
    font-size: 36px !important;
    line-height: 42px !important; 
    white-space: nowrap !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #062440 !important;
    margin-bottom: 30px !important;
  }
}
</style>

